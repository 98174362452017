import styled from 'styled-components'
import media from '../../common/utils/media'
import { GridItemContent } from '../../common/utils/types'

type GridItemProps = {
  icon: string
  title: string
  description: string
  contents?: GridItemContent[]
}

const GridItem = ({ icon, title, description, contents }: GridItemProps) => (
  <StyledGridItem>
    {icon && <img src={icon} alt={title} />}
    <h3>{title}</h3>
    <p>{description}</p>
    <div key={title} className="all-contents-container">
      {contents?.map((content: GridItemContent) => (
        <div
          key={`${content.title}-div`}
          className={`content-container ${
            content.className ? content.className : ''
          }`}
        >
          <h4>{content.title}</h4>
          <p>
            <span dangerouslySetInnerHTML={{"__html": content.description || ""}}/>
            {content.url && (
              <>
                <br />
                <br />
                <a href={content.url} target="_blank" rel="noreferrer">
                  {content.url}
                </a>
              </>
            )}
          </p>
          {content.image && (
            <img
              className="img-attachment"
              src={content.image}
              alt={content.title}
            />
          )}
        </div>
      ))}
    </div>
  </StyledGridItem>
)

export default GridItem

const StyledGridItem = styled.div`
  position: relative;
  & {
    text-align: center;
  }

  & > * {
    margin: 4px 0;
  }

  & > img {
    width: 40px;
    height: 40px;
  }

  h3 {
    font-size: 1.5em;
  }

  p {
    color: #8a979e;
    max-width: 900px;
    margin: 0 auto;
  }

  div.all-contents-container {
    margin-top: 0;
  }

  div.content-container {
    text-align: center;
  }

  img.img-attachment {
    width: 70%;
    height: 70%;
    border: 1.5px white solid;
    border-radius: 10px;
    max-width: 450px;
    margin-top: 20px;

    ${media.sp`
      width: 100%;
      height: 100%;
    `}
  }

  div.product-flow img {
    background: #152430;
    margin: 0 auto;
    display: block;
  }
`
