import styled from 'styled-components'
import { URL_APPLY_JOB } from '../../common/utils/constants'

type ApplyButtonProps = {
  lang?: string
  applicationNotes?: string
}

const ApplyButton = ({ lang, applicationNotes }: ApplyButtonProps) => {
  return (
    <StyledApplyButton>
      {lang === 'ja' ? (
        <>
          今すぐ
          <a href={URL_APPLY_JOB} target="_blank" rel="noreferrer">
            こちら
          </a>
          へご応募ください。
          {applicationNotes && <p>{applicationNotes}</p>}
        </>
      ) : (
        <>
          Apply now via{' '}
          <a href={URL_APPLY_JOB} target="_blank" rel="noreferrer">
            here
          </a>
          .
          {applicationNotes && <p>{applicationNotes}</p>}
        </>
      )}
    </StyledApplyButton>
  )
}

export default ApplyButton

const StyledApplyButton = styled.span`
  a {
    color: #ff3333;
    font-weight: bold;
  }

  a:hover {
    color: #fd5e5e;
  }
`
